import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const COOKIE_GROUPS = [
  { name: 'functional', accept: false },
  { name: 'statistics', accept: false },
  { name: 'marketing', accept: false },
];

const CookieContext = React.createContext(null);

const useCookies = () => React.useContext(CookieContext);

const CookieProvider = ({ children }) => {
  const [acceptedCookieGroups, setAcceptedCookieGroups] = React.useState(COOKIE_GROUPS);
  const [showCookieConsent, setShowCookieConsent] = React.useState(false);

  const get = (name) => JSON.parse(localStorage.getItem(name));

  const set = (name, value) => localStorage.setItem(name, JSON.stringify(value));

  const saveSettings = (groups, showConsent) => {
    if (!_.isEqual(groups, acceptedCookieGroups)) {
      setAcceptedCookieGroups(groups);
      set('mk.acg', JSON.stringify(groups));
    }
    setShowCookieConsent(showConsent);
    set('mk.hcc', !showConsent);
  }

  const acceptCookieGroup = (group) => {
    const groups = [];
    acceptedCookieGroups && acceptedCookieGroups.forEach((g) => {
      if (g.name === group) {
        groups.push({
          ...g,
          accept: true,
        });
      } else {
        groups.push(g);
      }
    });
    saveSettings(groups, false);
  }

  const toggleCookieGroup = (group) => {
    const groups = [];
    acceptedCookieGroups && acceptedCookieGroups.forEach((g) => {
      if (g.name === group) {
        groups.push({
          ...g,
          accept: !g.accept,
        });
      } else {
        groups.push(g);
      }
    });
    saveSettings(groups, true);
  }

  const acceptAllCookies = () => {
    const groups = [];
    COOKIE_GROUPS.forEach((g) => {
      groups.push({
        ...g,
        accept: true,
      });
    });
    saveSettings(groups, false);
  }

  const declineCookies = () => {
    const groups = [];
    COOKIE_GROUPS.forEach((g) => {
      groups.push({
        ...g,
        accept: false,
      });
    });
    saveSettings(groups, false);
  }

  const isAccepted = (group) => {
    let accepted = false;
    acceptedCookieGroups && acceptedCookieGroups.forEach((g) => {
      if (g.name === group && g.accept) {
        accepted = true;
      }
    });
    return accepted;
  }

  const saveConsent = () => saveSettings(acceptedCookieGroups, false);

  const cookieConsent = {
    acceptCookieGroup,
    acceptAllCookies,
    acceptedCookieGroups,
    declineCookies,
    isAccepted,
    saveConsent,
    showCookieConsent,
    setShowCookieConsent,
    toggleCookieGroup,
  };

  React.useEffect(() => {
    const cookieConsent = get('mk.hcc');
    const cookieGroups = JSON.parse(get('mk.acg'));
    if (cookieGroups) {
      setAcceptedCookieGroups(cookieGroups);
    }
    if (!cookieConsent) {
      setShowCookieConsent(true);
    }
  }, []);

  return (
    <CookieContext.Provider value={cookieConsent}>
      {children}
    </CookieContext.Provider>
  );
};

CookieProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CookieProvider.defaultProps = {
  children: [],
};

export { CookieProvider, useCookies };