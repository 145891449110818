import PropTypes from 'prop-types';
import React from 'react';

import { useCache } from './CacheProvider';

const USER = {
  bookmarks: [],
  showFilter: true,
  displayMode: 'list',
};

const UserContext = React.createContext(null);

const useUser = () => {
  const { setUser, user } = React.useContext(UserContext);

  const addBookmark = (lot) => {
    if (isBookmarked(lot)) return;
    const bookmarks = user.bookmarks || [];
    setUser({
      ...user,
      bookmarks: [
        ...bookmarks,
        lot._id,
      ],
    });
  };

  const isBookmarked = (lot) => user.bookmarks?.findIndex((b) => b === lot?._id) > -1;

  const removeBookmark = (lot) => {
    const bookmarks = user.bookmarks?.filter((b) => b !== lot?._id);
    setUser({
      ...user,
      bookmarks,
    });
  }

  const toggleBookmark = (lot) => isBookmarked(lot) ? removeBookmark(lot) : addBookmark(lot);

  const toggleFilter = () => {
    setUser({
      ...user,
      showFilter: !user.showFilter,
    });
  }

  const setDisplayMode = (mode) => {
    setUser({
      ...user,
      displayMode: mode,
    });
  }
 
  return { isBookmarked, setDisplayMode, toggleBookmark, toggleFilter, user };
};

// eslint-disable-next-line react/display-name
const withUser = (Component) => (props) => {
  const user = useUser();

  return <Component {...props} user={user} />;
};

const UserProvider = ({ children }) => {
  const cache = useCache();
  const [user, setUser] = React.useState(USER);
  
  React.useEffect(() => {
    const retrieveCachedUser = async () => {
      const store = await cache.get('user');
      if (store) setUser(store.value || {});
    };
    retrieveCachedUser();
  }, []);

  React.useEffect(() => {
    const updateCachedUser = async () => {
      await cache.set('user', user);
    };
    updateCachedUser();
  }, [user]);

  return (
    <UserContext.Provider value={{ setUser, user }}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

UserProvider.defaultProps = {
  children: [],
};

export { UserProvider, useUser, withUser };

