import React from 'react';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useConfig } from '../hooks/AppProvider';
import i18n from '../i18n';

const LanguageButton = () => {
  const { config } = useConfig();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const { languages } = config;

  const langDe = languages.find(l => l.id === 'de');
  const langEn = languages.find(l => l.id === 'en');

  const switchLanguage = () => {
    i18n.changeLanguage(i18n.getLang() === 'en' ? 'de' : 'en');
  };

  const iconStyle = mobile ? {
    height: '1.5rem',
  } : {
    height: '3rem',
    marginBottom: '0.5rem',
  };

  return (
    <div>
      <Button
        id="languageButton"
        color="light"
        aria-label={t('language')}
        onClick={switchLanguage}
        sx={{
          display: 'flex',
          flexDirection: mobile ? 'row' : 'column',
          minWidth: mobile ? 0 : 'inherit',
          alignItems: 'center',
          mr: mobile ? 0 : '1rem',
        }}
      >
        {i18n.getLang() === 'de' ? <>
          <img style={iconStyle} src={langEn?.icon} alt="english" />
          {!mobile ? <Typography sx={{ fontSize: '0.8rem' }}>switch to english</Typography> : null}
        </> : <>
          <img style={iconStyle} src={langDe?.icon} alt="Deutsch" />
          {!mobile ? <Typography sx={{ fontSize: '0.8rem' }}>auf Deutsch wechseln</Typography> : null}
        </>}
      </Button>
    </div>
  );
};

export default LanguageButton;
