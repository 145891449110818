import { Box, styled } from '@mui/material';
import PropTypes from 'prop-types';
import React, { lazy, Suspense } from 'react';

import ErrorBoundary from './ErrorBoundary';
import Loading from './Loading';
import { TransitionProvider } from '../hooks/TransitionProvider';
import NoMatch from '../pages/NoMatch';

const StyledBox = styled(Box)({
  paddingTop: '1px',
  zIndex: '0',
  position: 'relative',
  backgroundColor: 'background.lightgray',
  backgroundImage: 'url(/assets/img/background-gradient.png)',
  backgroundRepeat: 'repeat-x',
  backgroundPosition: 'top',
  boxShadow: 'inset 0 0.313em 0.625em rgb(0 0 0 / 10%)',
});

const StyledContent = ({ children, dataOutlet }) => !dataOutlet ? (
  <StyledBox>
    {children}
  </StyledBox>
) : children;

StyledContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  dataOutlet: PropTypes.bool,
};

StyledContent.defaultProps = {
  dataOutlet: false,
};

const Loader = ({ load }) => {
  if (!load || !load.component) return <NoMatch />;
  const { content, component, props, outlet, transition } = load;
  
  const folder = content ? 'components' : 'pages';
  const Component = React.useMemo(() => lazy(() => import(`../${folder}/${component}`)), [folder, component]);

  return (
    <StyledContent dataOutlet={outlet}>
      <ErrorBoundary component={load.component}>
        <TransitionProvider transition={transition}>
          <Suspense fallback={<Loading />}>
            <Component fetch={content} {...props} />
          </Suspense>
        </TransitionProvider>
      </ErrorBoundary>
    </StyledContent>
  );
};

Loader.propTypes = {
  load: PropTypes.shape({
    component: PropTypes.string,
    content: PropTypes.string,
    props: PropTypes.shape({}),
    outlet: PropTypes.bool,
    transition: PropTypes.string,
  }),
};

Loader.defaultProps = {
  load: {},
};

export default Loader;
