import { AnimatePresence, motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Container, Drawer, IconButton, styled, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import Logo from '../assets/Logo';
import { useNav } from '../hooks/NavProvider';
import { useConfig } from '../hooks/AppProvider';

const StyledNavigation = styled(Box)(({ theme }) => {
  const backgroundColor = theme.palette.background.blue;
  return ({
    backgroundColor,
    ul: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      position: 'relative',
    },
  });
});

const StyledDrawer = styled(Drawer)(({ theme }) => {
  const backgroundColor = theme.palette.background.blue;
  return ({
    '.MuiPaper-root': {
      width: '100vw',
      backgroundColor,
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    }
  });
});

const StyledNavLink = motion(styled(NavLink)(({ theme }) => {
  const matches = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  return ({
    display: 'block',
    fontFamily: 'bernhard_modern_bq',
    fontSize: matches ? '1.5vw' : '1.125rem',
    color: 'white',
    textDecoration: 'none',
    padding: '.875em .75em .75em .75em',
    textTransform: 'uppercase',
    transition: 'background-color 0.3s',
    '&.active': {
      backgroundColor: `${theme.palette.background.lightblue}`,
    },
    '&:hover': {
      backgroundColor: `${theme.palette.background.lighterblue}`,
    }
  });
}));

const SubnavBackground = motion(styled(Box)(({ theme }) => ({
  zIndex: 1,
  position: 'relative',
  height: '3.25rem',
  backgroundColor: `${theme.palette.background.gray}`,
})));

const hasSubmenu = (entry) => entry.children?.length && !entry.noSubmenu;

const getTranslatedEntry = (entry, lang) => entry.data?.filter((l) => l.lang === lang)[0]

const activeAlias = (alias, loc) => loc.pathname.includes(`/${alias}/`)

const Navigation = () => {
  const [showSub, setShowSub] = React.useState(false);
  const { refs } = useConfig();
  const { drawerOpen, menu, toggleDrawer } = useNav();
  const { i18n } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const smallMQ = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const lang = i18n.getLang();
  
  React.useEffect(() => {
    if (!mobile) {
      let show = false;
      menu.forEach((entry) => {
        const translatedAlias = getTranslatedEntry(entry, lang);
        if (activeAlias(translatedAlias?.alias, location) && hasSubmenu(entry)) {
          show = true;
        }
      });
      setShowSub(show);
    }
  }, [location.pathname, menu, mobile]);

  const isActive = (link, translatedLink) => activeAlias(translatedLink?.alias, location) && hasSubmenu(link);

  const renderLinks = (menu) => menu.map((link, index) => {
    const translatedLink = getTranslatedEntry(link, lang);
    return translatedLink?.title && (
      <li key={`${translatedLink.lang}/${translatedLink.alias}`}>
        <StyledNavLink
          className={isActive(link, translatedLink) ? 'active' : undefined}
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1, transition: { delay: index * 0.1 } }}
          exit={{ opacity: 0 }}
          to={translatedLink.redirect || translatedLink.alias}
          onClick={mobile && !hasSubmenu(link) ? toggleDrawer : null}
        >
          {translatedLink.title}
        </StyledNavLink>
        {isActive(link, translatedLink) ? mobile
          ? <MobileSubnavigation parent={translatedLink.alias} links={link.children} />
          : <Subnavigation parent={translatedLink.alias} links={link.children} /> : null}
      </li>
    );
  });

  return !mobile ? (
    <Box ref={refs.nav}>
      <StyledNavigation component="nav">
        <Container>
          <ul>
            {renderLinks(menu)}
          </ul>
        </Container>
      </StyledNavigation>
      <AnimatePresence mode="wait">
        {showSub ? (
          <SubnavBackground
            initial={{ height: 0 }}
            animate={{ height: '3.25rem' }}
            exit={{ height: 0 }}
          />
        ) : null}
      </AnimatePresence>
    </Box>
  ) : (
    <StyledDrawer
      anchor="left"
      open={drawerOpen}
      onClose={toggleDrawer}
    >
      <Container>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: smallMQ ? '1.5rem 0' : '0.5rem 0',
        }}>
          <Logo />
          <IconButton aria-label={i18n.t('general.close')} onClick={toggleDrawer} sx={{ color: 'white' }}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </Box>
        <ul>
          <StyledNavLink
            className={location.pathname === '/' ? 'active' : undefined}
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            to="/"
            onClick={toggleDrawer}
          >
            {i18n.t('general.home')}
          </StyledNavLink>
          {renderLinks(menu)}
        </ul>
      </Container>
    </StyledDrawer>
  );
};

const StyledSubNav = styled(Box)({
  zIndex: 2,
  position: 'absolute',
  top: '100%',
  left: 0,
});

const StyledSubNavLink = motion(styled(NavLink)(({ theme }) => ({
  display: 'block',
  fontSize: '1.125rem',
  color: `${theme.palette.text.primary}`,
  textDecoration: 'none',
  padding: '.75em .75em .65em .75em',
  whiteSpace: 'nowrap',
  '&.active': {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  }
})));

const Subnavigation = ({ links, parent }) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const activeRef = React.useRef();
  const [left, setLeft] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const lang = i18n.getLang();

  const hoverOver = (e) => {
    moveBar(e.target.offsetLeft, e.target.offsetWidth);
  }
  const initBar = () => {
    if (!activeRef.current) return;
    moveBar(activeRef.current.offsetLeft, activeRef.current.offsetWidth);
  }
  const moveBar = (left, width) => {
    setLeft(left);
    setWidth(width);
  }

  return (
    <StyledSubNav>
      <ul className="subnav">
        {links && links.map((link, index) => {
          const translatedLink = getTranslatedEntry(link, lang);
          const isActive = location.pathname.includes(translatedLink?.alias);
          return translatedLink?.title && (
            <li
              ref={isActive ? activeRef : undefined}
              key={`${translatedLink.lang}/${translatedLink.alias}`}
              onMouseOver={hoverOver}
              onMouseOut={initBar}
              onFocus={hoverOver}
              onBlur={initBar}
            >
              <StyledSubNavLink
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1, transition: { delay: index * 0.1, onComplete: initBar } }}
                exit={{ opacity: 0 }}
                to={`/${parent}/${translatedLink.alias}`}
              >
                {translatedLink.title}
              </StyledSubNavLink>
            </li>
          )
        })}
      </ul>
      <ActiveMarker left={left} width={width} />
    </StyledSubNav>
  );
};

Subnavigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      alias: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  parent: PropTypes.string,
};

Subnavigation.defaultProps = {
  links: [],
  parent: '',
};

const ActiveMarker = ({ left, width }) => (
  <Box
    sx={{
      position: 'absolute',
      left: `${left}px`,
      bottom: 0,
      backgroundColor: 'background.lighterblue',
      height: '4px',
      width: `${width}px`,
      transition: 'left 0.3s ease-out, width 0.3s ease-out',
      pointerEvents: 'none',
    }}
  />
);

ActiveMarker.propTypes = {
  left: PropTypes.number,
  width: PropTypes.number,
};

ActiveMarker.defaultProps = {
  left: 0,
  width: 0,
};

const StyledMobileSubNavLink = motion(styled(NavLink)(({ theme }) => ({
  display: 'block',
  fontSize: '1rem',
  color: theme.palette.text.mobileSubnav,
  textDecoration: 'none',
  padding: '.5em .75em .5em .75em',
  whiteSpace: 'nowrap',
  '&.active': {
    color: theme.palette.text.activeMobileSubnav,
  }
})));

const MobileSubnavigation = ({ links, parent }) => {
  const location = useLocation();
  const { toggleDrawer } = useNav();
  const { i18n } = useTranslation();
  const lang = i18n.getLang();
  return (
    <Box>
      <ul className="subnav">
        {links && links.map((link, index) => {
          const translatedLink = getTranslatedEntry(link, lang);
          const isActive = activeAlias(translatedLink?.alias, location);
          return translatedLink?.title && (
            <li key={`${translatedLink.lang}/${translatedLink.alias}`}>
              <StyledMobileSubNavLink
                className={isActive ? 'active': ''}
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1, transition: { delay: index * 0.1 } }}
                exit={{ opacity: 0 }}
                to={`/${parent}/${translatedLink.alias}`}
                onClick={toggleDrawer}
              >
                {translatedLink.title}
              </StyledMobileSubNavLink>
            </li>
          )
        })}
      </ul>
    </Box>
  );
};

MobileSubnavigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      alias: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  parent: PropTypes.string,
};

MobileSubnavigation.defaultProps = {
  links: [],
  parent: '',
};

export default Navigation;
