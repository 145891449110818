export default {
  translation: {
    archive: {
      title: 'Archiv',
      subtitle: 'Online Kataloge früherer Auktionen',
      search: {
        title: 'Suchen',
        label: 'Volltextsuche',
      },
    },
    auctions: {
      button: {
        label: 'Zur Auktion',
      },
      empty: 'Es sind noch keine Auktionen veröffentlicht.',
      nonPlanned: 'Es sind noch keine Auktionen geplant.',
    },
    bidform: {
      address: '<h2 class="logo">RIPPON BOSWELL & CO. GmbH</h2>Friedrichstrasse 45<br />D - 65185 Wiesbaden<br />Germany<p>Tel.: +49 (0)611 - 33 44 30<br /><strong>Telefax: +49 (0)611 - 3 34 43 26</strong></p>',
      auctionPassed: 'Aktuell gibt es keine Auktion für die Sie Gebote abgeben können.',
      info: 'Bitte bieten Sie an meiner Stelle bei der Auktion am <strong>{{date}}</strong> für die nachstehende Losnummer (die nachstehenden Losnummern) bis zu dem angegebenen Betrag (den angegebenen Beträgen). Alle erteilten Aufträge sind zu den niedrigst möglichen Zuschlägen auszuführen, entsprechend den im Katalog ausgedruckten Versteigerungsbedingungen, die ich hiermit durch meine Unterschrift ausdrücklich anerkenne. Das Aufgeld des Versteigerers auf den Zuschlag beträgt <strong>{{provision}} Prozent</strong> zuzüglich der gesetzlichen Mehrwertsteuer von <strong>{{vat}} Prozent</strong>.',
      title: 'Gebotsformular',
      success: 'Bitte drucken Sie das erstellte PDF aus und senden es unterschrieben entweder postalisch oder via Fax an die oben genannte Adresse.',
      date: 'Datum',
      signature: 'Unterschrift',
      pdf: {
        address: 'RIPPON BOSWELL & CO. GmbH\nFriedrichstrasse 45\nD - 65185 Wiesbaden\nGermany\n\nTel.: +49 (0)611 - 33 44 30\nTelefax: +49 (0)611 - 3 34 43 26',
        filename: 'Auftragsformular',
        info: 'Bitte bieten Sie an meiner Stelle bei der Auktion am {{date}} für die nachstehende Losnummer (die nachstehenden Losnummern) bis zu dem angegebenen Betrag (den angegebenen Beträgen). Alle erteilten Aufträge sind zu den niedrigst möglichen Zuschlägen auszuführen, entsprechend den im Katalog ausgedruckten Versteigerungsbedingungen, die ich hiermit durch meine Unterschrift ausdrücklich anerkenne. Das Aufgeld des Versteigerers auf den Zuschlag beträgt {{provision}} Prozent zuzüglich der gesetzlichen Mehrwertsteuer von {{vat}} Prozent. ',
        title: 'Auftragsformular',
      },
    },
    catalog: {
      back: {
        archive: 'Archiv',
        results: 'Ergebnisse',
      },
      details: {
        open: 'Detailansicht öffnen',
        close: 'Detailansicht schließen',
      },
      emptyWishlist: 'Sie haben noch keine Lose in ihrer Wunschliste gespeichert.',
      exchangeRates: {
        title: 'Sortenkurs',
        eur: '{{eur}} EUR',
        gbp: '{{gbp}} GBP',
        usd: '{{usd}} USD',
      },
      filter: {
        byBookmarks: 'Nach Wunschliste filtern',
        hide: 'Filter verbergen',
        reset: 'Filter zurücksetzen',
        show: 'Filter anzeigen',
      },
      fullscreen: {
        close: 'Vollbildansicht schließen',
        open: 'Vollbildansicht öffnen',
      },
      fulltextsearch: 'Volltextsuche',
      literature: 'Lit.:',
      lotsPerPage: 'Anzahl Lots/Seite',
      noAuction: 'Keine Auktion gefunden',
      noLots: 'Keine Lose gefunden',
      origin: 'Herkunft',
      print: 'Drucken',
      published: 'Publ.:',
      sortBy: 'Sortieren nach',
      sortByLotNoAsc: 'Lot Nr. aufsteigend',
      sortByLotNoDesc: 'Lot Nr. absteigend',
      sortByEstimateAsc: 'Schätzpreis aufsteigend',
      sortByEstimateDesc: 'Schätzpreis absteigend',
      sortByResultAsc: 'Ergebnis aufsteigend',
      sortByResultDesc: 'Ergebnis absteigend',
      sortByOriginAsc: 'Herkunft aufsteigend',
      sortByOriginDesc: 'Herkunft absteigend',
      sortByWidthAsc: 'Breite aufsteigend',
      sortByWidthDesc: 'Breite abstgeigend',
      sortByHeightAsc: 'Höhe aufsteigend',
      sortByHeightDesc: 'Höhe abstgeigend',
      title: 'Online Katalog {{number}}',
      view: {
        grid: 'Rasteransicht',
        list: 'Listenansicht',
        table: 'Tabellenansicht',
      },
    },
    catalogorder: {
      auctionCatalog: 'den Auktionskatalog',
      catalogSubscription: 'Katalogabonnement ab Katalog',
      currentCatalog: 'Aktueller Katalog',
      title: 'Katalogbestellung',
      info: 'Beachten Sie bitte, dass Ihre Bestellung nur dann bearbeitet werden kann, wenn alle mit einem * markierten Pflichtfelder ausgefüllt sind.',
      incVatAndPostageDe: '{{price}},- {{currency}} inkl. MwSt. und Porto Inland',
      incVatAndPostageEu: '{{price}},- {{currency}} inkl. MwSt. und Porto Europa',
      incVatAndPostageOverseas: '{{price}},- {{currency}} inkl. MwSt. und Porto Übersee',
      order: 'Ich bestelle:',
      paymentType: {
        label: 'Zahlweise:',
        visa: 'VISA/Mastercard',
        wiretransfer: 'Banküberweisung',
      },
      send: 'Absenden',
      success: 'Ihre Katalogbestellung wurde erfolgreich versendet.',
      error: {
        'captcha-failed': 'Captcha Validierung fehlgeschlagen',
      },
    },
    contact: {
      title: 'Kontakt',
      info: 'Hier können Sie uns per E-Mail eine Nachricht zukommen lassen.<br />Diese wird dann an unsere E-Mail Adresse <strong>info@rippon-boswell-wiesbaden.de</strong> weitergeleitet.',
      warning: 'Beachten Sie bitte, dass Ihre Mail nur dann bearbeitet werden kann, wenn die mit einem <strong>*</strong> markierten Pflichtfelder ausgefüllt sind.',
      success: 'Ihre Kontaktanfrage wurde erfolgreich versendet.',
      error: {
        'captcha-failed': 'Captcha Validierung fehlgeschlagen',
      },
    },
    cookieConsent: {
      title: 'Cookie-Einstellungen',
      info: 'Diese Website nutzt Cookies und vergleichbare Funktionen zur Verarbeitung von Endgeräteinformationen und personenbezogenen Daten. Die Verarbeitung dient der Einbindung von Inhalten, externen Diensten und Elementen Dritter sowie der statistischen Analyse/Messung. Je nach Funktion werden dabei Daten an Dritte weitergegeben. Ihre Einwilligung ist stets freiwillig und kann jederzeit über den Link "Cookie-Einstellungen" am Ende der Seite abgelehnt oder widerrufen werden.',
      button: {
        accept: 'Alle akzeptieren',
        decline: 'Nur erforderliche',
        imprint: 'Impressum',
        imprintLink: '/ueber-uns/impressum',
        moreDetails: 'Mehr Informationen',
        lessDetails: 'Weniger Informationen',
        privacyPolicy: 'Datenschutzhinweise',
        privacyPolicyLink: '/ueber-uns/datenschutz',
        save: 'Speichern',
        unblockFunctional: 'Funktionale Cookies erlauben',
        unblockMarketing: 'Marketing-Cookies erlauben',
      },
      cookies: {
        i18next: {
          purpose: 'Wird von i18next verwendet um die Spracheinstellung zu speichern.',
          validity: 'Unbegrenzt',
        },
        hideCookieConsent: {
          purpose: 'Speichert Sitzungsübergreifend ob die Cookie-Einstellungen angezeigt werden sollen.',
          validity: 'Unbegrenzt',
        },
        acceptedCookieGroups: {
          purpose: 'Speichert Sitzungsübergreifend welchen Cookiegruppen zugestimmt wurde.',
          validity: 'Unbegrenzt',
        },
        hcaptcha: {
          purpose: 'Wird auf Formularen verwendet um sicherzustellen dass Sie eine echte Person und kein sog. Bot sind.',
          validity: 'Aktuelle Session',
          blocked: 'Wir setzen HCaptcha ein um Spamanfragen zu verhindern. Damit Sie das Formular verwenden können, müssen Sie funktionale Cookies akzeptieren.',
        },
        pkSess: {
          purpose: 'Wird von Matomo verwendet um den aktuellen Besucherzugriff aufzuzeichnen.',
          validity: '30 Minuten',
        },
        pkId: {
          purpose: 'Wird von Matomo verwendet um den Besucher zu identifizieren.',
          validity: '13 Monate',
        },
        googlemaps: {
          purpose: 'Google Maps Kartenanzeige',
          validity: 'Aktuelle Session',
          blocked: 'Damit Sie die interaktive Google Karte benutzen können, müssen Sie Marketing-Cookies akzeptieren.'
        },
      },
      group: {
        essential: 'Erforderliche Cookies',
        functional: 'Funktionale Cookies',
        statistics: 'Analytische Cookies',
        marketing: 'Marketing-Cookies',
      },
      table: {
        name: 'Name',
        provider: 'Anbieter',
        purpose: 'Zweck',
        validity: 'Laufzeit',
      },
    },
    dates: {
      title: 'Auktionstermine {{date, YYYY}}',
      info: 'Wir freuen uns auf Ihre Einlieferungen. Für die kostenlose Bewertung in unseren Geschäftsräumen stehen wir Ihnen nach telefonischer Terminabsprache jederzeit zur Verfügung.<p>Bei Sammlungen und bedeutenden Einzelobjekten nehmen wir die Bewertung gerne auch vor Ort und Stelle vor. In solchen Fällen bitten wir Sie vorab um Einsendung von Fotos mit Maßangaben und Zustandsbeschreibungen.</p>',
      dateFormat: 'DD.MM.YYYY',
      hour: 'ab {{hour}} Uhr',
      hourFormat: 'H',
      longDateFormat: 'dddd, D. MMMM YYYY [um] H [Uhr]',
    },
    form: {
      address: 'Anschrift',
      consent: {
        title: 'Einwilligung in die Verarbeitung personenbezogener Daten:',
        label: 'Ich stimme der Verarbeitung meiner personenbezogenen Daten zu und habe die Datenschutzerklärung gelesen.',
        help: 'Wir nutzen Ihre hier angegeben personenbezogenen Daten nur zum Zweck der Beantwortung Ihrer Fragen [Anfrage] und geben Ihre Daten nicht an Dritte weiter. Resultiert aus dieser Anfrage keine Geschäftsanbahnung oder Geschäftsbeziehung, werden Ihre Daten nach Zweckerfüllung gelöscht. Sie können der Verarbeitung und Speicherung jederzeit widersprechen mit einer E-Mail an info@rippon-boswell-wiesbaden.de',
      },
      country: 'Staat',
      email: 'E-Mail-Adresse',
      error: {
        address: {
          required: 'Bitte geben Sie ihre Anschrift ein.',
        },
        bid: {
          required: 'Bitte geben Sie ein Höchstgebot ein.',
          min: 'Bitte geben Sie ein Höchstgebot ein.',
        },
        captcha: {
          required: 'Bitte bestätigen Sie die Captcha Abfrage',
        },
        consent: {
          required: 'Stimmen Sie bitte der Datenverarbeitung zu, damit wir ihre Anfrage bearbeiten können.',
        },
        country: {
          required: 'Bitte geben Sie den Staat an.',
        },
        email: {
          required: 'Bitte geben Sie ihre E-Mail-Adresse ein.',
          valid: 'Bitte geben Sie eine gültige Email-Adresse ein.',
          min: 'Bitte geben Sie die Mindestanzahl Zeichen ein',
        },
        firstname: {
          required: 'Bitte geben Sie ihren Vornamen ein.',
        },
        lastname: {
          required: 'Bitte geben Sie ihren Nachnamen ein.',
        },
        message: {
          required: 'Bitte schildern Sie ihre Frage oder Kurzmitteilung.',
        },
        mixed: {
          default: 'Bitte korrigieren Sie dieses Feld.',
          required: 'Bitte füllen Sie dieses Feld aus',
        },
        name: {
          required: 'Bitte geben Sie ihren Namen ein.',
        },
        payment: {
          required: 'Bitte wählen Sie die Zahlungsart.',
        },
        phone: {
          required: 'Bitte geben Sie ihre Telefonnummer ein.',
        },
        street: {
          required: 'Bitte geben Sie Strasse und Hausnr. ein.',
        },
        subscription: {
          required: 'Bitte entscheiden Sie sich für ein Katalogabo oder die aktuelle Ausgabe des Online Katalogs.',
        },
        token: {
          required: 'Bitte bestätigen Sie die Captcha Abfrage.',
        },
        zip: {
          required: 'Bitte geben Sie PLZ und Wohnort ein.',
        },
      },
      fax: 'Telefax',
      firstname: 'Vorname',
      lastname: 'Nachname',
      name: 'Name',
      passportReminder: 'Bitte senden Sie uns eine Kopie Ihres Ausweises.',
      phone: 'Telefon',
      print: 'Drucken',
      salecode: 'Kennwort',
      send: 'Senden',
      sender: 'Absender',
      street: 'Straße',
      streetAndNo: 'Straße und Hausnr.',
      message: 'Ihre Frage oder Kurzmitteilung',
      zipAndCity: 'PLZ und Ort',
    },
    general: {
      add: 'Hinzufügen',
      address: 'Friedrichstraße 45,<br />D-65185 Wiesbaden,<br />Tel. +49 (0)611 – 33 44 30<br />E-Mail: info@rippon-boswell-wiesbaden.de<br />www.rippon-boswell-wiesbaden.de',
      all: 'Alle',
      auctionDate: 'am {{date}}',
      close: 'Schließen',
      filename: '{{date, YYYY-MM-DD}} {{name}}',
      filter: 'Filter',
      goToOverview: 'Zur Übersicht',
      home: 'Start',
      letterDate: 'Wiesbaden, {{date, DD.MM.YYYY}}',
      loading: 'wird geladen...',
      longDateFormat: '{{date, dddd, Do MMMM YYYY}}',
      menu: 'Menü',
      more: 'mehr',
      page: 'Seite',
      of: ' von ',
      scrollTop: 'Zum Seitenanfang',
    },
    highlights: {
      title: 'Highlights',
      subtitle: 'Highlights unserer Auktionen seit Herbst 2007',
    },
    language: 'Sprache',
    location: {
      title: 'Anfahrt',
      linkTitle: 'Größere Kartenansicht',
    },
    lots: {
      add_one: 'Los hinzufügen',
      add_other: 'Lose hinzufügen',
      afterSale: 'Nachverkauf',
      age: 'Alter',
      bid: 'Höchstgebot in €',
      bidForLot: 'Bieten Sie auf ein Los.',
      bookmark: {
        remove: 'Von Wunschliste entfernen',
        save: 'Zur Wunschliste hinzufügen',
      },
      description: 'Bezeichnung',
      details: {
        hide: 'Details verstecken',
        show: 'Details anzeigen',
      },
      dimensions: 'Abmessungen',
      estimate: 'Schätzpreis',
      estimateIn: 'Schätzpreis in {{currency}}',
      filename: {
        archive: 'Online Katalog A{{auction.number}}',
        highlights: 'Highlights',
        'online-catalog': 'Online Katalog A{{auction.number}}',
        results: 'Ergebnisliste A{{auction.number}}',
      },
      filterByBookmarks: 'Nach Wunschliste filtern',
      headCellLabel: {
        image: '',
        number: 'Lot Nr.',
        title: 'Bezeichnung',
        age: 'Alter',
        dimensions: 'Maße',
        estimate: 'Schätzpreis in EUR',
      },
      images: {
        alternate: 'Weitere Lotansichten',
        next: 'Nächstes Bild',
        previous: 'Vorheriges Bild',
      },
      info: {
        hide: 'Informationen ausblenden',
        show: 'Informationen anzeigen',
      },
      label: 'Lose',
      limit: 'Limit',
      limitIn: 'Limit in {{currency}}',
      navigate: {
        next: 'Weiter zu Lot {{number}}',
        previous: 'Zurück zu Lot {{number}}',
      },
      noBidsError_one: 'Bitte geben Sie für das ausgewählte Los ein Höchstgebot ein.',
      noBidsError_other: 'Bitte geben Sie für die ausgewählten Lose Höchstgebote ein.',
      noSelection: 'Noch keine Lose ausgewählt',
      noSelectionError: 'Bitte wählen Sie mindestens ein Los aus.',
      number: 'Lot Nr.',
      origin: 'Herkunft',
      perPage: 'Lots pro Seite:',
      removeBid: 'Gebot entfernen',
      result: 'Resultat inkl. Käuferprovision',
      resultIn: 'Resultat in {{currency}} inkl. Käuferprovision',
      rowDisplay: '{{page}} von {{count}}',
      select: 'Lose auswählen',
      selectAll: 'Alle Lose auswählen',
      selectWithCount_one: '{{count}} Los ausgewählt',
      selectWithCount_other: '{{count}} Lose ausgewählt',
      sizeComparison: {
        hide: 'Größenvergleich ausblenden',
        show: 'Größenvergleich anzeigen',
      },
      surcharge: 'Zuschlag',
      surchargeIn: 'Zuschlag in {{currency}}',
      title: 'Lot Name',
    },
    news: {
      title: 'Mitteilungen',
    },
    newsletter: {
      title: 'Gesendete Newsletter',
    },
    results: {
      title: 'Ergebnisse unserer Auktionen',
    },
    search: {
      title: 'Suchergebnisse',
      subtitle: 'Suche nach {{phrase}} über alle Auktionen',
    },
    subscribe: {
      title: 'Newsletter Abonnieren',
      consent: {
        title: 'Einwilligung in die Verarbeitung personenbezogener Daten:',
        label: 'Ich stimme der Verarbeitung meiner personenbezogenen Daten zu und habe die Datenschutzerklärung gelesen.',
        help: 'Wir nutzen Ihre hier angegeben personenbezogenen Daten, um Sie über unsere Aktivitäten via E-Mail auf dem Laufenden zu halten. Sie können Ihre Entscheidung jederzeit widerrufen, indem Sie den Abmeldelink in der Fußzeile jeder E-Mail, die Sie von uns erhalten, anklicken oder indem Sie das Abmeldeformular auf unserer Website nutzen. Wir behandeln ihre Daten mit Sorgfalt.',
      },
      error: {
        default: 'Es sind Fehler bei der Newsletteranmeldung aufgetreten.',
        'subscriber-exists': 'Die E-Mail-Adresse ist bereits im Verteiler.',
        'captcha-failed': 'Captcha Validierung fehlgeschlagen',
      },
      send: 'Abonnieren',
      success: 'Sie haben sich erfolgreich zu unserem Newsletter angemeldet. Bitte bestätigen Sie ihre E-Mail-Adresse über den ihnen zugesandten Link.',
    },
    unsubscribe: {
      error: {
        default: 'Es sind Fehler bei der Newsletterabmeldung aufgetreten.',
        'captcha-failed': 'Captcha Validierung fehlgeschlagen',
      },
      title: 'Newsletter Abbestellen',
      submit: 'Abbestellen',
      success: 'Sie haben sich erfolgreich von unserem Newsletterverteiler ausgetragen.',
    },
    zoomify: {
      zoomIn: 'Hineinzoomen',
      zoomOut: 'Rauszoomen',
      reset: 'Ansicht Zurücksetzen',
      rotateLeft: 'Nach links drehen',
      rotateRight: 'Nach rechts drehen',
    },
  }
}